.projects-view-container{
  margin-bottom: 1rem;

  .projects-line-one, .projects-line-two, .projects-line-three{
    white-space: nowrap;
    overflow: hidden;
    word-spacing: -10px;
    background-color:none;
    span{
      background-color: none;
      font-size: 0.9rem;
    }
  }
  .projects-line-one{
    height: 83px;
    span{
      line-height: 83px;
    }

    @media (max-width: 550px) {
      height: 22vw;
      span{
        line-height: 10vw;
        font-size: 12vw;
      }
    }
  }
  .projects-line-two{
    line-height: 83px;
    span{
      height: 83px;
    }
    :not(span.outline){
      color: rgba(255,255,255,0.5)
    }
    .outline{
      -webkit-text-stroke: 2px rgba(255,255,255,0.5);
    }
    h1{
      transform: translateX(-180%);
    }

    @media (max-width: 550px) {
      line-height: 8vw;
      span{
        height: 20vw;
        font-size: 12vw;
      }
    }

  }

  .project-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:nth-of-type(3){
      margin-top: 2rem;
      margin-bottom: 2.3rem;
      @media (max-width: 950px) {
        margin-top: 3.5rem;
        margin-bottom: 3.5rem;
      }
    } 
    &:nth-of-type(4){
      margin-bottom: 2.3rem;
      @media (max-width: 950px) {
        margin-bottom: 4rem;
      }
    } 
    &:nth-of-type(5){
      margin-bottom: 2.3rem;
      @media (max-width: 950px) {
        margin-bottom: 3.8rem;
      }
    } 
    &:nth-of-type(6){
      margin-bottom: 3.5rem;
      // @media (max-width: 950px) {
      //   margin-top: 4rem;
      // }
    } 

    .thumbnail{
      z-index: 100;
      max-width: 9rem;
      box-shadow: 2px 2px 10px 2px rgba(255, 255, 255, 0.5);

      @media (max-width: 950px) {
        max-width: 80vw;
      }

    }
    .project-title{
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: 0.2rem;
      width: 9rem;
      h3{
        font-size: 0.6rem;
        @media (max-width: 950px) {
          font-size: 0.2rem;
        }
      }

      @media (max-width: 950px) {
        max-width: 80vw;
      }

    }

    .project-info{
      display: flex;
      flex-direction: row;
      width: 9rem;
      margin-top: 0.5rem;

      @media (max-width: 950px) {
        margin-top: 0.4rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .detail-line-days, .detail-line-team{
        display: flex;
        align-items: center;
        margin-bottom: 0.1rem;
        img{
          height: 0.4rem;
          margin-right: 0.1rem;
          @media (max-width: 950px) {
            height: 0.2rem;
          }
        }
        p{
          font-size: 0.2rem;
          @media (max-width: 950px) {
            font-size: 0.15rem;
          }
        }
      }
      .tech-used{
        margin-top: 0.5rem;
        margin-bottom: 0.2rem;
        // @media (max-width: 950px) {
        //   margin-top: 0.35rem;
        //   font-size: 0.15rem;
        // another tech line below
        // }
      }
      .tech-line{
        display: flex;
        align-items: center;
        width: 2.5rem;
        flex-wrap: wrap;
        @media (max-width: 950px) {
          margin-bottom: 20px;
        }
        img{
          height: 0.5rem;
          @media (max-width: 950px) {
            height: 0.4rem;
          }
        }
        .icon-wrapper{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 0.45rem;
          margin-right: 0.2rem;
          @media (max-width: 950px) {
            width: 0.35rem;
          }
          .tooltipText{
            visibility: hidden;
            color: darken(coral, 10%);
            text-align: center;
            position: relative;
            padding-top: 4px;
            @media (max-width: 950px) {
              font-size: 0.1rem;
              padding-top: 2px;
            }
          }
          &:hover .tooltipText{
            visibility: visible;
          }
        }
      }
      .project-left{
        .tech-line{
          width: 2.5rem;
        }
        padding-left: 0.2rem;
        @media (max-width: 950px) {
          padding-left: 0;
        }
        @media (max-width: 550px) {
          padding-left: 0;
          width: 80vw;
          font-size: 0.15rem;
        }
        @media (min-width: 550px) and (max-width: 950px) {
          padding-left: 0;
          width: 80vw;
          font-size: 0.15rem;
          // display: flex;
          // flex-direction: row;
        }
        .detail-lines{
            display: flex;
            flex-direction: column;
        }
        .tech-container{
          .tech-used{
            @media (max-width: 950px) {
              margin-top: 0;
              font-size: 0.15rem;
            }
          }
          @media (max-width: 950px) {
            margin-left: 0.5rem;
          }  
        }
        

      }
      .project-right{
        border-left: 1px solid white;
        width: 6.5rem;
        padding-left: 0.5rem;
        padding-right: 0.2rem;
        @media (max-width: 950px) {
          width: 80vw;
          border-top: 1px solid white;
          border-left: 0;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .buttons{
      .open-project, .open-repo{
        width: 0.7rem;
        transition: 0.5s;
        &:hover{
          scale: 1.25;
          cursor: pointer;
        }

        @media (max-width: 950px) {
          width: 0.35rem;
        }
      }
      .open-project{
        border-radius: 50%;
        animation: rotateButton 8s linear infinite;
        -webkit-box-shadow: 0 0 10px 2px rgb(255, 255, 255);
        box-shadow: 0 0 10px 2px #fff;
        
      }
      .open-repo{
        margin-left: 0.5rem;
        border-radius: 50%;
        animation: rotateButton 7.5s linear infinite;
        -webkit-box-shadow: 0 0 5px 2px #fff;
        box-shadow: 0 0 10px 2px #fff;
        @media (max-width: 950px) {
          margin-left: 0.25rem;
        }
        
      }
      @keyframes rotateButton {
        0%{
          transform: rotate(0deg);
        }
        100%{
          transform: rotate(360deg);
        }
      }
    }
    
    .info{
      @media (max-width: 950px) {
        padding-top: 20px;
      }
      p{
        margin-bottom: 0.3rem;
        text-align: justify;
        line-height: 0.3rem;
        @media (max-width: 950px) {
          font-size: 0.15rem;
        }
      }
    }
    // hr{
    //   height: 0.025rem;
    //   background-color: white;
    //   margin-bottom: 1rem;
    // }
  }
  

  

  .info{
    
    p{
      font-size: 0.2rem;
    }
  }


@media (max-width: 1100px){
  // width: 200px;
  overflow: hidden;
}

}
.about-view-container{
  // height: 100vh;

  .about-line-one, .about-line-two, .about-line-three{
    white-space: nowrap;
    overflow: hidden;
    word-spacing: -10px;
    background-color:none;
    span{
      background-color: none;
      font-size: 0.9rem;
    }
  }
  .about-line-one{
    height: 83px;
    span{
      line-height: 83px;
    }
    h1{
      transform: translateX(-150%);
    }

    @media (max-width: 550px) {
      height: 22vw;
      span{
        line-height: 10vw;
        font-size: 12vw;
      }
    }

  }
  .about-line-two{
    line-height: 83px;
    span{
      height: 83px;
    }
    :not(span.outline){
      color: rgba(255,255,255,0.5)
    }
    .outline{
      -webkit-text-stroke: 2px rgba(255,255,255,0.5);
    }
    h1{
      transform: translateX(-100%);
    }

    @media (max-width: 550px) {
      line-height: 8vw;
      span{
        height: 20vw;
        font-size: 12vw;
      }
    }


  }


  // .about-text-container{
    .about-text{
      max-width: 10rem;
      margin: 0 auto;
      line-height: 100px;
      font-size: 0.5rem;
      padding-left: 10px;
      // text-align: justify;
      span{
        font-size: 0.7rem;
        color: darken(coral, 10%);

        @media (max-width: 550px) {
          font-size: 0.35rem;
        }

      }
      .oops{
        font-size: 0.3rem;
        color: lighten(coral, 20%);

        @media (max-width: 550px) {
          font-size: 0.15rem;
        }

      }
      &:nth-of-type(1){
        margin-top: 2.6rem;
      }
      &:nth-of-type(2){
        margin-top: 2rem;

        @media (max-width: 550px) {
          margin-top: 2.5rem;
        }

      }
      &:nth-of-type(3){
        margin-top: 2.5rem;

        @media (max-width: 550px) {
          margin-top: 5.5rem;
        }

      }
      &:nth-of-type(4){
        margin-top: 2.1rem;
        margin-bottom: 4rem;

        @media (max-width: 550px) {
          margin-top: 4rem;
        }


      }

      @media (max-width: 550px) {
        margin: 0 40px;
        font-size: 0.25rem;
        line-height: 70px;
      }

    }
  // }
  
  
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&family=Raleway:wght@500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 100px;
  scroll-behavior: smooth !important;
}

body{
  height: 100vh;
  // overflow-y: auto;
  // overflow: hidden;
  background-color: none;
  color: white;
  
  // background-image: url('images/star-long-2.jpg');
  background-size: cover;
  background-attachment: fixed;
  @media (max-width: 550px) {
    width: 100vw;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10%;
  }
  background-color: rgb(0, 0, 20);
}

@font-face{
  font-family: MonumentExtended-Ultrabold;
  src: url('./styles/MonumentExtended-Ultrabold.otf');
}
@font-face{
  font-family: MonumentExtended-Regular;
  src: url('./styles/MonumentExtended-Regular.otf');
}
@font-face{
  font-family: Circular-Std-Medium-500;
  src: url('./styles/Circular-Std-Medium-500.ttf');
}
@font-face{
  font-family: Circular-Std-Book;
  src: url('./styles/Circular-Std-Book.ttf');
}

::-webkit-scrollbar{
  // width: 10px;
  display: none;
}

// ::-webkit-scrollbar-track{
//   background: (0, 0, 0, 0.2)
// }

// ::-webkit-scrollbar-thumb{
//   background: hsl(0 0% 80% / 0.2)
// }

// ::-webkit-scrollbar-thumb:hover{
//   background: hsl(0 0% 99% / 0.8)
// }

h1 {
  font-family: MonumentExtended-Ultrabold;
  font-size: 0.9rem;
}

h2 {
  font-family: MonumentExtended-Ultrabold;
  font-size: 0.5rem;
}

h3{
  font-family: Circular-Std-Medium-500;
  font-size: 0.4rem;
}

.outline{
  color: transparent;
  -webkit-text-stroke: 2px white;
}

p{
  font-family: Circular-Std-Book;
  font-size: 0.2rem;
}

.tooltipText, .tooltipText-1{
  font-family: Circular-Std-Book;
  font-size: 0.15rem;
}

a{
  font-family: Circular-Std-Book;
  font-size: 0.2rem;
  text-decoration: none;
}

.click-to-open{
  font-family: MonumentExtended-Ultrabold;
  p{
    font-family: MonumentExtended-Ultrabold;
  }
}

.black{
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  // background-image: url('images/star-long-2.jpg');
  // background-size: cover;
  @media (max-width: 550px) {
    width: 100%;
  }
  background-color: rgb(0, 0, 20);
}

// .moon{
//   min-width: 100vw;
//   transform: translateY(10%);
//   // border: 0px solid yellow;
//   border-radius: 50%;
//   box-shadow: 0px -100px 200px rgba(255, 255, 255, 0.4);
//   position: relative;
//   @media (max-width: 550px) {
//     width: 100%;
//     transform: translateY(170vw);
//   }
// }

.counter{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  width: 100%;
  top: 50%;
  // transform: translateY(-0%);
  bottom: 100;
  // animation: fadeOut 1s;
  // animation-delay: 3.5s;
  .number{
    font-family: MonumentExtended-Regular;
    font-size: 1.5rem;
    color: rgb(255, 255, 255, 0.5);
    -webkit-text-stroke: 2.5px black;
    @media (max-width: 550px) {
      font-size: 1rem;
    }
  }
  .percent{
    font-family: MonumentExtended-Regular;
    font-size: 0.45rem;
    color: black;
    // -webkit-text-stroke: 2px white;
    color: rgb(255, 255, 255, 0.5);
    -webkit-text-stroke: 2px black;
    @media (max-width: 550px) {
      font-size: 0.3rem;
    }
  }
}
@keyframes fadeOut {
  0% {
  opacity: 1;
  }
  100% {
  opacity: 0;
  }
}


.contact-view-container{
  // height: 100vh;

  .contact-line-one, .contact-line-two, .contact-line-three{
    white-space: nowrap;
    overflow: hidden;
    word-spacing: -10px;
    background-color:none;
    span{
      background-color: none;
      font-size: 0.9rem;
    }
  }
  .contact-line-one{
    height: 83px;
    span{
      line-height: 83px;
    }
    h1{
      transform: translateX(-200%);
    }

    @media (max-width: 550px) {
      height: 22vw;
      span{
        line-height: 10vw;
        font-size: 12vw;
      }
    }


  }
  .contact-line-two{
    line-height: 83px;
    span{
      height: 83px;
    }
    :not(span.outline){
      color: rgba(255,255,255,0.5)
    }
    .outline{
      -webkit-text-stroke: 2px rgba(255,255,255,0.5);
    }
    h1{
      transform: translateX(-100%);
    }

    @media (max-width: 550px) {
      line-height: 8vw;
      span{
        height: 20vw;
        font-size: 12vw;
      }
    }

  }

  .find{
    display: flex;
    justify-content: center;
    font-size: 0.3rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    @media (max-width: 550px) {
      font-size: 0.2rem;
    }
  }
  .contact-line{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.25rem;
    p, a{
      @media (max-width: 550px) {
        font-size: 0.15rem;
      }
    }
    img{
      height: 40px;
      margin-right: 0.2rem;
      @media (max-width: 550px) {
        height: 30px;
        margin-right: 0.15rem;
      }
    }
    &:last-of-type{
      margin-bottom: 3rem;
    }
  }
  footer{
    display: flex;
    justify-content: center;
    padding-bottom: 0.1rem;
    p{
      font-size: 0.15rem;
      @media (max-width: 550px) {
        font-size: 0.1rem;
      }
    }
  }
  a{
    color: white;
    text-decoration: none;
  }
}

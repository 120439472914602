.title-view-container{
  min-height: 100%;
  position: relative;
  padding-top: 54.25vh;
  padding-bottom: 36.1666666vh;
  .line-container{
    // margin-bottom: 20%;
    margin-bottom: 36.1666666vh;
    height: 45.75vh;
    .title-line-one, .title-line-two{
      white-space: nowrap;
      overflow: hidden;
      word-spacing: -10px;
      span{
        background-color: none;
        font-size: 1.1rem;
        // font-size: 108%;
      }
      h1{
        background-color: none;
      }
    }
    .title-line-one{
      height: 0.92rem;
      span{
        line-height: 0.92rem;
      }

      @media (max-width: 550px) {
        height: 22vw;
        span{
          line-height: 10vw;
          font-size: 12vw;
        }
      }

    }
    .title-line-two{
      line-height: 0.92rem;
      span{
        height: 0.92rem;
      }
      h1{
        transform: translateX(-97.5%);
      }

      @media (max-width: 550px) {
        line-height: 8vw;
        span{
          height: 20vw;
          font-size: 12vw;
        }
        h1{
          transform: translateX(-97.5%);
        }
      }
    }
  }

  // .tech-container{
  //   background-color: rgb(0,0,40);
  // }

  h3{
    // margin-top: 50px;
    text-align: center;
    @media (max-width: 550px) {
      font-size: 0.3rem;
    }
  }
  .icons-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 600px;
    margin: 50px auto 100px auto;

    @media (max-width: 550px) {
      margin: 40px;
    }

    .icon-box{
      padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        height: 120px;
      }
      p{
        text-align: center;
        @media (max-width: 550px) {
          font-size: 0.15rem;
        }
      }

      @media (max-width: 550px) {
        padding: 2.5px;
        padding-bottom: 20px;
        img{
          height: 80px;
          width: 60px;
        }
      }

    }
  }
  // h2{
  //   text-align: center;
  //   color: yellow;
  // }
  .arrow{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 150px;
    margin-top: 10px;
  }




  // @media (max-width: 550px) {
  //   background-color: blue;
  // }
}